import { Message } from 'react-hook-form';

import { createUrlQuery } from '../helpers/create-url-query';
import { UserLogTableForm } from '../pages/main/history-log-page/use-history-log-page';
import { NotificationsListTableForm } from '../pages/main/notifications-page/use-notifications-page';
import {
  ApiFetchCommonParams,
  ApiPaginationResponse,
  ApiPaginationResponseNotification,
  ApiResponse,
  ApiStaffList,
} from '../types/api';
import { TableFetchCommonParams } from '../types/api/table';
import {
  FetchDocuments,
  FetchDocumentsDeleteProps,
  FetchDocumentsProps,
  FetchUserUploadAvatarParams,
} from '../types/api/user';
import { CourseSelectProps } from '../types/course';
import { ExamProps } from '../types/exams';
import { Profile } from '../types/profile';
import { StatusProps } from '../types/status';
import { SubjectProps } from '../types/subjects';
import { TagProps } from '../types/tag-props';
import {
  ApiPaginationStatisticsListResponse,
  FetchActivateCourseProps,
  FetchActivateDemoCourseProps,
  FetchAddExamModalProps,
  FetchAddSubjectModalProps,
  FetchProfileDialogProps,
  FetchTransferConnectProps,
  fetchUserSearchListParams,
  FetchUsersProps,
  NotificationsList,
  SearchUserList,
  SettingList,
  StatisticsList,
  UtmUsers,
} from '../types/user-info/user';
import { UserLog } from '../types/user-info/user-info-type';
import { apiRequest } from './index';

export const fetchUserUploadAvatar = async ({
  data,
  errorToast,
  ...params
}: FetchUserUploadAvatarParams): Promise<ApiResponse<string>> =>
  apiRequest({ data, errorToast, method: 'post', url: `/user/upload-avatar${createUrlQuery(params)}` });

export const fetchUserDeleteExam = async (props: ApiFetchCommonParams): Promise<ApiResponse<string>> =>
  apiRequest({ url: `/user/delete-exam${createUrlQuery(props)}` });

export const fetchUserTags = async (): Promise<ApiResponse<TagProps>> =>
  apiRequest({ errorToast: { show: false }, url: '/user/tags' });

export const fetchUserCurator = async (
  props: ApiFetchCommonParams
): Promise<ApiPaginationResponse<Array<{ key: number; value: string }>>> =>
  apiRequest({ url: `/user-curator/get-curator-list${createUrlQuery(props)}` });

export const fetchUserStatus = async (): Promise<ApiResponse<StatusProps>> =>
  apiRequest({ errorToast: { show: false }, url: '/user/status' });

export const fetchUserInactiveCourse = async (): Promise<ApiResponse<CourseSelectProps>> =>
  apiRequest({ errorToast: { show: false }, url: '/course/not-active-courses' });

export const fetchRoles = async () => apiRequest({ url: '/user/roles' });

export const fetchTeachers = async (): Promise<ApiStaffList> =>
  apiRequest({ method: 'post', url: '/user/index?role=teacher&limit=500' });

export const fetchCurators = async (): Promise<ApiStaffList> =>
  apiRequest({ method: 'post', url: '/user/index?role=curator&limit=500' });

export const fetchRopms = async (): Promise<ApiStaffList> =>
  apiRequest({ method: 'post', url: '/user/index?role=ropm&limit=500' });

export const fetchRops = async (): Promise<ApiStaffList> =>
  apiRequest({ method: 'post', url: '/user/index?role=rop&limit=500' });

export const fetchManagers = async (): Promise<ApiStaffList> =>
  apiRequest({ method: 'post', url: '/user/index?role=manager&limit=500' });

export const fetchPersonalManagers = async (): Promise<ApiStaffList> =>
  apiRequest({ method: 'post', url: '/user/index?role=personalManager&limit=500' });

export const fetchEmployer = async (role: string): Promise<ApiPaginationResponse<Profile[]>> =>
  apiRequest({ method: 'post', url: '/user/index', params: { role, limit: 500 } });

export const fetchUsers = async ({
  courseIds,
  no_registration,
  ...props
}: TableFetchCommonParams<FetchUsersProps>): Promise<ApiPaginationResponse<Profile[]>> =>
  apiRequest({
    data: { courseIds },
    method: 'post',
    url: `/user/index${createUrlQuery({ askSymbol: true, ...props })}&no_registration=${no_registration}`,
  });

export const fetchUsersUtm = async (
  props: TableFetchCommonParams<FetchUsersProps>
): Promise<ApiPaginationResponse<UtmUsers[]>> =>
  apiRequest({
    url: `/utm/index${createUrlQuery(props)}`,
  });

export const fetchProfileDialog = async ({ url, data }: FetchProfileDialogProps): Promise<ApiResponse<Message>> =>
  apiRequest({ data, errorToast: { show: false }, method: 'post', url });

export const fetchProfileTransferCurator = async ({
  data,
  ...props
}: FetchTransferConnectProps): Promise<ApiResponse<Message>> =>
  apiRequest({
    data,
    errorToast: { show: false },
    method: 'post',
    url: `/user/transfer-curator${createUrlQuery(props)}`,
  });

export const fetchProfileTransferManager = async ({
  data,
  ...props
}: FetchTransferConnectProps): Promise<ApiResponse<Message>> =>
  apiRequest({
    data,
    errorToast: { show: false },
    method: 'post',
    url: `/user/transfer-manager${createUrlQuery(props)}`,
  });

export const fetchActivateCourse = async ({
  data,
  ...props
}: FetchActivateCourseProps): Promise<ApiResponse<Message>> =>
  apiRequest({
    data,
    errorToast: { show: false },
    method: 'post',
    url: `/user/activate-course${createUrlQuery(props)}`,
  });

export const fetchActivateDemoCourse = async ({
  data,
  ...props
}: FetchActivateDemoCourseProps): Promise<ApiResponse<Message>> =>
  apiRequest({
    data,
    errorToast: { show: false },
    method: 'post',
    url: `user/activate-demo-course${createUrlQuery(props)}`,
  });

export const fetchAddSubjectModal = async ({ url, data }: FetchAddSubjectModalProps): Promise<ApiResponse<Message>> =>
  apiRequest({ data, method: 'post', url });

export const fetchAddExamModal = async ({ url, data }: FetchAddExamModalProps): Promise<ApiResponse<Message>> =>
  apiRequest({ data, errorToast: { show: false }, method: 'post', url });

export const fetchUserSearchList = async (props: fetchUserSearchListParams): Promise<ApiResponse<SearchUserList>> =>
  apiRequest({ url: `/user/user-list${createUrlQuery(props)}` });

export const fetchUserExam = async (): Promise<ApiResponse<ExamProps>> =>
  apiRequest({ errorToast: { show: false }, url: '/user-course/exam-list' });

export const fetchUserCourseSubjectList = async (): Promise<ApiResponse<SubjectProps>> =>
  apiRequest({ errorToast: { show: false }, url: '/user-course/subject-list' });

export const fetchCourseType = async (): Promise<ApiResponse<CourseSelectProps>> =>
  apiRequest({ errorToast: { show: false }, url: '/user-course/type-list' });

export const fetchUserAccess = () => apiRequest({ url: '/access-user/index' });

export const fetchSetOnline = (): Promise<ApiResponse<string>> => apiRequest({ url: '/user/set-online' });

export const fetchUserLog = async (
  props: TableFetchCommonParams<UserLogTableForm>
): Promise<ApiPaginationResponse<UserLog[]>> => apiRequest({ url: `/log/index${createUrlQuery(props)}` });

export const fetchNotificationList = async (
  props: TableFetchCommonParams<NotificationsListTableForm>
): Promise<ApiPaginationResponseNotification<NotificationsList[]>> =>
  apiRequest({
    url: `/notification/index${createUrlQuery(props)}`,
  });

export const fetchCheckNotifications = async (): Promise<ApiResponse<string>> =>
  apiRequest({
    url: '/notification/show',
  });

export const fetchStatisticsList = async (
  props: TableFetchCommonParams<NotificationsListTableForm> & { userId: string | undefined }
): Promise<ApiPaginationStatisticsListResponse<StatisticsList[]>> =>
  apiRequest({
    url: `/statistic/index${createUrlQuery(props)}`,
  });

export const fetchDocuments = async (props: FetchDocumentsProps): Promise<ApiPaginationResponse<FetchDocuments[]>> =>
  apiRequest({
    url: `/documents/index${createUrlQuery(props)}`,
  });

export const fetchDeleteDocuments = async (props: FetchDocumentsDeleteProps): Promise<ApiResponse<string>> =>
  apiRequest({
    url: `/documents/delete${createUrlQuery(props)}`,
  });
export const fetchDocumentsDeleteComment = async (props: FetchDocumentsDeleteProps): Promise<ApiResponse<string>> =>
  apiRequest({
    url: `/documents/delete-comment${createUrlQuery(props)}`,
  });

export const fetchThemeSearchList = async (props: {
  exam?: number;
  number_job?: number;
  q?: string;
  subject?: number;
}): Promise<ApiResponse<SearchUserList>> =>
  apiRequest({
    url: `/job-bank-theme/list?q=${props.q}&exam=${props.exam}&subject=${props.subject}&number_job=${props.number_job}`,
  });
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// export const fetchThemeSearchList = async (props: { q: string }): Promise<ApiResponse<SearchUserList>> =>
//   apiRequest({ url: `/job-bank-theme/list${createUrlQuery(props)}` });

export const fetchSettingList = async (): Promise<ApiResponse<SettingList[]>> =>
  apiRequest({
    url: '/settings/index',
  });

export const fetchVkLogIn = async (): Promise<ApiResponse<string>> =>
  apiRequest({
    url: '/settings/registration-vk',
  });

export const fetchVkLogOut = async (): Promise<ApiResponse<string>> =>
  apiRequest({
    url: '/settings/no-registration-vk',
  });

export const fetchPersonalDataOn = async (): Promise<ApiResponse<string>> =>
  apiRequest({
    url: '/settings/enable?type=personal_data',
    method: 'POST',
  });

export const fetchPersonalDataOff = async (): Promise<ApiResponse<string>> =>
  apiRequest({
    url: '/settings/no-enable?type=personal_data',
    method: 'POST',
  });
